<style>
    .dropdown-menu {
        display: none;
    }

    .nav-item:focus-within .dropdown-menu {
        display: block;
    }
    
    .banner-area {
    background: linear-gradient(to right, red 0%, red 20%, rgb(237, 142, 142) 50%, red 70%, red 100%);
    text-align: center;
    width: 100%;
}

.cyber_security {
    animation: blinker 3s linear infinite;
}

.icon-container {
    width: 40px; 
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
}

.icon-container img {
    width: 24px; 
    height: 24px;
}
#navbarArea {
    margin-top: 75px;
}

.navbar-area.is-sticky {
    margin-top: 0px !important;
}

@media screen and (max-width: 768px) {
    #navbarArea {
        margin-top: 200px; 
    }
}

@media screen and (max-width: 480px) {
    #navbarArea {
        margin-top: 120px; 
    }
    .icon-container {
        width: 67px !important;
    }
}

</style>

<!-- Start Navbar Area -->


<div class="banner-area text-white py-3">
    <div class="container d-flex justify-content-center align-items-center">
        <div class="d-flex align-items-center cyber_security">
            <div class="rounded-circle bg-white p-2 me-3 icon-container">
                <img src="/assets/img/cyber-security-home/cyber-security.png" alt="Cybersecurity Icon">
            </div>
            
            <p class="mb-0 text-white text-center">
                <b>X-Biz TechVentures is now empaneled by CERT-In as an Information Security Auditing Organization..</b>
            </p>
        </div>
    </div>
</div>








<div class="navbar-area {{navbarClass}}" id="navbarArea" style="float:inline-start;">
    <div class="xbiz-nav">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/Logo.svg" width="35%" alt="logo"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <!-- <li class="nav-item">
                            <a  routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">
                                Home 
                            </a>
                        </li> -->
                  
                        <li class="nav-item">
                            <a routerLink="/Digivision" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"  class="nav-link">DigiVision  <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/TrueCheck" routerLinkActive="active" class="nav-link">TrueCheck</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/Redaction-Solution" routerLinkActive="active" class="nav-link">PII Masking</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/Offline-Vision-Engine" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Offline Vision Engine</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/TrustTier" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">TrustTier</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/iMagica-Suite" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Digi iMagica</a>
                                </li>
                            </ul>    
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/SecureNexus-ISS" routerLinkActive="active" class="nav-link">
                                Secure Nexus <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/SecureNexus-ISS" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Information Security Services</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/SecureNexus-GRC" routerLinkActive="active" class="nav-link">Governance Risk & Compliance</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/Digidrive"  class="nav-link">DigiDrive</a>
                        </li>
                    <!-- Innovation Lab -->
                    <li class="nav-item ">
                        <a routerLink="/Innovation" routerLinkActive="active" class="nav-link">
                            Innovation Lab <i class="fas fa-chevron-down"></i>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <a routerLink="/pocketoffice" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Pocket Office</a>
                            </li>
                           
                        </ul>
                    </li>
                    
                    

                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-bs-toggle="dropdown" aria-expanded="false">Use Cases <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/Banking" routerLinkActive="active" class="nav-link">Banking & Finance</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/Insurance" routerLinkActive="active" class="nav-link">Insurance</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/Healthcare" routerLinkActive="active" class="nav-link">Healthcare</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/Tele-com" routerLinkActive="active" class="nav-link">Tele-Communication</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/AboutUs" class="nav-link">Company <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/AboutUs" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">About Us</a>
                                </li>
                                 <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/Career" routerLinkActive="active" class="nav-link">Career</a>
                                </li>
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/Press-Release" routerLinkActive="active" class="nav-link">Press Release</a>
                                </li> 
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                    <a routerLink="/Blog-Details" routerLinkActive="active" class="nav-link">Blog</a>
                                </li> 
                            </ul>
                        </li>
                      
                    </ul>
                    <div class="others-options">
                        <div class="option-item"><button routerLink="/"  class="default-btn burger-menu">Request Demo &nbsp; <i class="fa fa-arrow-up-right-dots"></i> <span></span></button>
                       
                          
                        </div>
                    
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->